import React from "react"
import Layout from "../components/Layout";

const Blog : React.FC = () => {
  return (
    <Layout>
        <p>This is awesome gatsby blog page!</p>
    </Layout>
  );
}

export default Blog;